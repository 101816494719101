
//火车票退票订单下载
const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_trains_order_refund_trainRefundOrderListCenterExport = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/t-od-trains-order-refund/trainRefundOrderListCenterExport',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_t_od_trains_order_refund_trainRefundOrderListCenterExport;


