import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'


//火车票退票订单列表
import consumer_t_od_trains_order_refund_trainRefundOrderListCenter from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_refund_trainRefundOrderListCenter'
//火车票退票订单列表分类数量
import consumer_t_od_trains_order_refund_trainRefundOrderCountByStatus from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_refund_trainRefundOrderCountByStatus'
//火车票退票订单下载
import consumer_t_od_trains_order_refund_trainRefundOrderListCenterExport from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_refund_trainRefundOrderListCenterExport'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
export default {
    data() {
        return {
            loading: false,
            orderStatusData: 0,
            searchForm: {
                currentPage: 1,   //当前页码
                deptIds: [],//部门集合
                deptIdList: [],
                keyword: '', //不明物体
                orderBy: '',//排序条件
                orderNo: '',   // 订单号
                pageSize: 10,   //每页记录条数
                passengerName: '', //退票乘客
                refundEndTime: '',//申请退票结束时间
                refundNo: '',//退票单号
                refundOrderStatus: 0,//退票单状态:0：全部 1：申请退票  2：退票中  3:退票成功  4：退票失败  5：已取消
                refundStartTime: '',//申请退票开始时间
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数
            activeName: '0',
            tabList: [
                // 退票单状态：0=全部 1=申请退款 2.退款中 3=退票成功 4=退票失败 5=取消退票
                {
                    name: "0",
                    label: "全部",
                    value: 0
                },
                {
                    name: "1",
                    label: "申请退款",
                    value: 0
                },
                {
                    name: "2",
                    label: "退票中",
                    value: 0
                },
                {
                    name: "3",
                    label: "退票成功",
                    value: 0
                },
                {
                    name: "4",
                    label: "退票失败",
                    value: 0
                },
                {
                    name: "5",
                    label: "取消退票",
                    value: 0
                },
            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getDeptList();
        this.getOrderList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.refundStartTime = moment(val[0]).format('YYYY-MM-DD 00:00:00');
                    this.searchForm.refundEndTime = moment(val[1]).format('YYYY-MM-DD 23:59:59');
                } else {
                    this.searchForm.refundStartTime = '';
                    this.searchForm.refundEndTime = '';
                }
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        onSearch() {
            this.searchForm.currentPage = 1;
            this.getOrderList();
        },
        onReset() {
            this.searchForm = {
                currentPage: 1,   //当前页码
                    deptIds: [],//部门集合
                deptIdList: [],
                    keyword: '', //不明物体
                    orderBy: '',//排序条件
                    orderNo: '',   // 订单号
                    pageSize: 10,   //每页记录条数
                    passengerName: '', //退票乘客
                    refundEndTime: '',//申请退票结束时间
                    refundNo: '',//退票单号
                    refundOrderStatus: 0,//退票单状态:0：全部 1：申请退票  2：退票中  3:退票成功  4：退票失败  5：已取消
                    refundStartTime: '',//申请退票开始时间
            };
            //重置日期选择器
            this.applyTime = [];
            this.onSearch()
        },
        getOrderList() {
            this.loading = true;
            this.searchForm.refundOrderStatus = parseInt(this.activeName);
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_t_od_trains_order_refund_trainRefundOrderListCenter(this.searchForm).then(res => {
                this.orderList = res.datas.list;
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = [];
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            });

            this.postTrainOrder();
        },
        //获取订单各状态数量
        postTrainOrder(){
            //预处理订单个状态数量
            let dataForm = this.searchForm;
            dataForm.refundOrderStatus = 0;

            consumer_t_od_trains_order_refund_trainRefundOrderCountByStatus(dataForm).then(res => {
                res.datas.trainRefundOrderCountVoList.forEach((item,index) => {
                    this.tabList.forEach((tabItem,tabIndex) => {
                        if (item.refundOrderStatus == tabItem.name) {
                            tabItem.value = item.orderCount;
                        }
                    })
                });
            })
        },
        //切换
        tabClick(data){
            this.orderStatusData = Number(data.name);
        },
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false;
            this.searchForm.refundOrderStatus = this.orderStatusData;
            consumer_t_od_trains_order_refund_trainRefundOrderListCenterExport(this.searchForm).then(res => {
                let url = res.datas.url;
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    });
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-train-refund-details',
                query: {
                    orderNo: val.refundNo,
                    type: 'orderCenter'
                }
            })
        },
        getStatusToClass(val) {
            // 退票单状态：1=申请退票、2=退票中、3=退票成功、4=退票失败、5=取消退票
            if (val === 1 || val === 2) {
                return 'yellow-text'
            } else if (val === 3) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
    }
}
